import type { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeVarsAvr, buildThemeMixinsAvr, buildThemeWidgetStylesAvr } from 'dw-uk-avr/theme';

const createTheme: ThemeCreateFunction = () =>
  buildTheme(
    (vars: Pick<Theme, CommonThemeVars>) => merge({}, buildThemeVarsAvr(vars)),
    buildThemeMixinsAvr,
    (vars: Omit<Theme, 'widgets'>) => merge({}, buildThemeWidgetStylesAvr(vars)),
  );

export default createTheme;