import { Arsenal, Libre_Caslon_Text } from 'next/font/google';

const libreCaslonTextFont = Libre_Caslon_Text({
  weight: ['400', '700'],
  style: ['normal'],
  subsets: ['latin'],
});

const arsenalFont = Arsenal({
  weight: ['400', '700'],
  subsets: ['latin'],
});

export { arsenalFont, libreCaslonTextFont };
