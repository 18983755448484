/**
 * Theme for Averys(AVR) UK site
 **/
import type { Theme } from '@emotion/react';
import { buildTheme, globals } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types.d';
import { buildThemeMixinsLW, buildThemeWidgetStylesLW, buildThemeVarsLW } from 'dw-uk-law/theme';
import { libreCaslonTextFont, arsenalFont } from '../common/nextFont';

export const buildThemeVarsAvr = (vars: Pick<Theme, CommonThemeVars>) => {
  //primary
  const avrForest = '#003D40'; //color1
  const avrGreen = '#20513B'; //color2
  const avrCream = '#F6F5F2'; //color3
  const avrLightGreen = '#E9EEEB'; //color3

  //secondary
  const avrPurple = '#78166D'; //color4
  const avrGreenOld = '#62A65C'; //color5
  const avrDarkGreen = '#012C1F'; //color6
  const avrPink = '#9C6B8E'; //color7
  const avrDarkGreenOld = '#004E33'; //color8
  const avrMustard = '#D29948'; //color9
  const avrCarbon = '#333333';
  const avrSquant = '#666666';
  const avrSvgCheckboxBg = '%2320513B';

  //CTA colors
  const avrCTAGreen = avrGreen;
  const avrCTARolloverDarkGreen = avrDarkGreen;
  const avrCTAPurple = avrPurple;
  const avrCTARolloverPink = '#6F1D3E';

  const libreCaslonText = libreCaslonTextFont.style.fontFamily;
  const arsenal = arsenalFont.style.fontFamily;

  const lwThemeVars = buildThemeVarsLW(vars);

  return merge({}, lwThemeVars, {
    meta: {
      name: 'Averys UK',
    },
    icons,
    assets: {
      headerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      headerLogoMobileImgUrl: `${globals.assetsPath}/next/images/brand-logo-mobile.svg`,
      footerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo-dark.svg`,
    },
    colors: {
      primary1: avrGreen,
      primary2: avrGreen,
      primary3: avrCream,
      primary5: avrLightGreen,
      secondary1: avrPurple,
      secondary2: avrGreenOld,
      secondary3: avrDarkGreen,
      secondary4: avrPink,
      secondary5: avrDarkGreenOld,
      secondary6: avrMustard,
      secondary7: avrCarbon,
      secondary8: avrSquant,

      //specific colour definitions
      textPrimaryBg: avrGreen,
      btnsPrimaryBg: avrCTAGreen,
      btnsPrimaryColor: vars.colors.white,
      btnsPrimaryHoverBg: avrCTARolloverDarkGreen,
      btnsPrimaryHoverColor: vars.colors.white,
      btnsSecondaryBg: vars.colors.transparent,
      btnsSecondaryColor: vars.colors.black,
      btnsSecondaryHoverBg: avrCTARolloverDarkGreen,
      btnsSecondaryHoverColor: vars.colors.white,
      btnsTertiaryBg: vars.colors.transparent,
      btnsTertiaryColor: vars.colors.black,
      btnsTertiaryBorder: vars.colors.transparent,
      btnsTertiaryHoverBg: vars.colors.transparent,
      btnsTertiaryHoverColor: vars.colors.white,
      svgCheckboxBg: avrSvgCheckboxBg,
      savingsTextColor: avrGreen,
      bgScarcityColor: avrCTARolloverPink,

      linkPrimaryColor: vars.colors.black,
      linkSecondaryColor: avrCTAGreen,
      linkTertiaryColor: avrCTAPurple,

      headerCartBtn: avrCTAGreen,
      headerCartBtnSmall: avrCTAGreen,
      headerSignInBtnSmall: avrCTAGreen,

      footerPrimaryBg: avrForest,
      footerGuidelinesFillColor: avrForest,
      footerIconFillColor: vars.colors.white,
      footerdrinkaware: vars.colors.white,

      bgColors: {
        AVRForest: avrForest,
        AVRKhaki: avrGreen,
        AVRCream: avrCream,
        AVRPurple: avrPurple,
        AVRGreen: avrGreenOld,
        AVRMidnight: avrDarkGreen,
        AVRPink: avrPink,
        AVRDarkGreen: avrDarkGreenOld,
        AVRMustard: avrMustard,
      },
    },
    fonts: {
      sizeH1: '35px',
      sizeH2: '26px',
      sizeH3: '18px',
      sizeH4: '16px',
      familyPrimary: arsenal,
      familySecondary: lwThemeVars.fonts.roboto,
      familyTertiary: libreCaslonText,
      headerMainNavTitle: libreCaslonText,
      headerMainNavSubTitle: lwThemeVars.fonts.roboto,
    },
  });
};

export const buildThemeMixinsAvr = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  const themeStyles = buildThemeMixinsLW(vars);

  return merge({}, themeStyles, {
    hxl: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize44};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing2};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize60};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight100};
      }
    `,
    h1: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize38};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing2};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize48};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight105};
      }
    `,
    h2: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize32};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize38};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight105};
      }
    `,
    h3: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize28};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize32};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    h4: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize26};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize28};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h5: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize24};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize26};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h6: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize20};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize22};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h7: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightMedium};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    h8: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightBold};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing6};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    eyebrowRegular: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize14};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    eyebrowSmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    utility: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing9};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
        line-height: ${vars.fonts.fontLineHeight100};
        letter-spacing: ${vars.fonts.fontLetterSpacing8};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    quote: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize20};
      font-weight: ${vars.fonts.weightMedium};
      font-style: ${vars.fonts.styleItalic};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize26};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    body1: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    body2: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize14};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    bodySmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    caption: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
  });
};

export const buildThemeWidgetStylesAvr = (vars: Omit<Theme, 'widgets'>) => {
  const themeStyles = buildThemeWidgetStylesLW(vars);

  return merge({}, themeStyles, {
    Footer: {
      default: `
       ${themeStyles.Footer.default}
       .footer-bottom-section {
         background: ${vars.colors.footerPrimaryBg};
         box-shadow: 0 0 0 100vmax ${vars.colors.footerPrimaryBg};
         clip-path: inset(0 -100vmax);
         color: ${vars.colors.white};
         .wrapper {
            .copyrights {
              p {
                color: ${vars.colors.white};
                a {
                  color: ${vars.colors.white};
                }
              }
            }
         }
        }
       .social-connections {
          background: ${vars.colors.footerPrimaryBg};
          box-shadow: 0 0 0 100vmax ${vars.colors.footerPrimaryBg};
          clip-path: inset(0 -100vmax);
          .wrapper {
            .social-connection-heading p, i {
              color: ${vars.colors.white};
            }
          }
       }
     `,
      navItems: `
       ${themeStyles.Footer.navItems}
     `,
    },
    Search: {
      hitWrapper: `
      ${themeStyles.Search.hitWrapper}
      .image-container{
        .offer{
          svg path:first-of-type{
            fill:#AC5F25!important;
          }
        }
      }
      `,
      wrapper: `
      ${themeStyles.Search.wrapper}
      .filter-header {
        p {
          font-weight: ${vars.fonts.weightMedium};
        }
      }
      `,
    },
    Membership: {
      wrapper: `
       ${themeStyles.Membership.wrapper}
      margin-top: 15px;

      flex-direction: column;

      ${vars.breakpoints.md} {
        flex-direction: row;
      }
      .banner-content_container{
        & h2{
        font-family: ${vars.fonts.familyTertiary};
            font-size: ${vars.fonts.fontSize26};
            font-weight: ${vars.fonts.weightNormal};
            text-transform: unset;
        }
      }
    .card-container {
      .card-layout-wrapper {
        
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;

        ${vars.breakpoints.sm} {
          grid-template-columns: repeat(3, 1fr); 
        }

        .membership-card {
            width: auto;
            height: auto;

          .cancel-membership {
            width: 100%;

            .cancel-membership-text {
              font-size: 14px;
              width: 80%;
            }
          }

          .change-payment-card a {
            text-decoration: underline;

            :hover {
              text-decoration: none;
            }
          }
        }

        .faq-card {
          grid-column: 1/-1;

          ${vars.breakpoints.sm} {
            grid-column: 1/3;
            &.active{
              grid-column: 2/4;
            }
          }
        }
        
       }
      }

      .mobile-sidebar-view {
        display: flex;
        flex-direction: column;
        padding:0px;

        > .membership-sidebar {
          width: 100%;
          background: ${vars.colors.grey10};
          height: fit-content;
          padding-left: 20px;
          margin-bottom: 10px;
        }

        ${vars.breakpoints.md} {
          display: none;
        }


        > .sidebar-dropdown-text {
            display: block;
            width: 100%;
            border-color: ${vars.colors.grey20};
            color: ${vars.colors.secondary8};
            height: 40px;
            cursor: pointer;
            border: 1px solid transparent;
            border-radius: 5px;
            font-size: 14px;
            font-weight: bold;
        }
      }

      .hide-sidebar {
        display: none;
      }

      .sidebar-view {
        > .membership-sidebar {
          background: ${vars.colors.grey10};
          height: fit-content;
          margin-bottom: 10px;
          max-width: 230px;
          margin-right: 15px;
          padding-left: 12px;
        }
      }

      .sidebar {

      > .membership-sidebar {
        width: 100%;
        background: ${vars.colors.grey10};
        height: fit-content;
        padding-left: 20px;
        margin-bottom: 10px;
        
        ${vars.breakpoints.md} {
          max-width: 230px;
          margin-right: 15px;
          padding-left: 12px;
        }

        > img {
          width: 14px;
          height: 14px;
        }
         & .navigation-link {
            max-height: fit-content;
            > h4 {
              font-size: 16px;
              color: ${vars.colors.secondary7};
              margin-top: 12px;
              margin-bottom: 15px;
              text-wrap: nowrap;
              font-family: "adobe-caslon-pro-n6","adobe-caslon-pro","Adobe Caslon Pro",serif;
              width: fit-content;
            }
            & a{
              font-size:14px;
              color: ${vars.colors.footerPrimaryBg};
              margin-left: 4px;
              margin-bottom: 10px;
              text-wrap: nowrap;
              height: 24px;
              font-family: ${vars.fonts.familySecondary};
              width: fit-content;
              display: flex;
              gap: 10px;
              > div {
                overflow-y: hidden;
                > img {
                  width: 24px;
                  height: 24px;
                }
              }
            }
         }
      }
    }`,
    },
    Cart: {
      Pill: `
      ${themeStyles.Cart.Pill}
      background-color: ${vars.colors.bgScarcityColor};
      `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsAvr, buildThemeMixinsAvr, buildThemeWidgetStylesAvr);
export default createTheme;
